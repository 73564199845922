import React, { useState, useEffect } from "react";

import {
  useContractRead,
  useContractWrite,
  parseEther,
  useBalance,
} from "wagmi";
import { useAccount } from "wagmi";

import { contractData } from "../utils/web3-utils";
import { toWeiToDecimals, fromWeiToDecimals } from "../utils/web3-utils";

const Presale = () => {
  const [lumAmount, setLumAmount] = useState(0);
  const [maticAmount, setMaticAmount] = useState(0);
  // const [walletBalance, setWalletBalance] = useState("");
  const [LumValuePerMatic, setLumValuePerMatic] = useState(0);

  const { address } = useAccount();

  const {
    data: walletBalance,
    isError,
    isLoading,
  } = useBalance({
    address: address,
  });

  const { data: tokenPerMatic } = useContractRead({
    address: contractData?.luminaiPresale,
    abi: contractData?.luminaiPresaleABI,
    functionName: "tokenPerMatic",
  });

  const { data: totalUser } = useContractRead({
    address: contractData?.luminaiPresale,
    abi: contractData?.luminaiPresaleABI,
    functionName: "totalUsers",
  });

  const { write: buy, isLoading: isBuyLoading } = useContractWrite({
    address: contractData?.luminaiPresale,
    abi: contractData?.luminaiPresaleABI,
    functionName: "buy",
    value: toWeiToDecimals(maticAmount),
  });

  const { data: getBalance } = useContractRead({
    address: contractData?.luminaiPresale,
    abi: contractData?.luminaiPresaleABI,
    functionName: "getBalance",
  });

  console.log("matic amount in input ", maticAmount);
  console.log("tokenPer matic", 1 / fromWeiToDecimals(tokenPerMatic));
  console.log("all amount", lumAmount);
  console.log("total users", totalUser);
  console.log("account balance", walletBalance);
  console.log("lum value per matic", LumValuePerMatic);
  console.log("LUM balance", getBalance);

  useEffect(() => {
    if (maticAmount && !isNaN(maticAmount) && tokenPerMatic) {
      const calculatedLumAmount =
        maticAmount * fromWeiToDecimals(tokenPerMatic);
      setLumAmount(calculatedLumAmount);
    } else {
      setLumAmount(0);
    }
  }, [maticAmount, tokenPerMatic]);

  useEffect(() => {
    setLumValuePerMatic(1 / fromWeiToDecimals(tokenPerMatic));
  }, [tokenPerMatic]);

  return (
    <div className="presale-container">
      <div className="deposit-form ">
        <label>Amount in MATIC you pay</label>
        <div className="inputBox">
          <input
            className="inputamount"
            required=""
            type="number"
            step="any"
            min={16}
            max={10000000}
            placeholder="Enter Amount ..."
            onChange={(e) => setMaticAmount(e.target.value)}
          />
          <button className="usdt-btn">
            <img className="usdt-bep20" src="image/usdt.png" alt="matic" />
          </button>
        </div>

        <label>Amount in LUM you receive</label>
        <div className="inputBox">
          <input
            className="inputamount"
            required=""
            type="number"
            step="any"
            min={16}
            max={10000000}
            placeholder="Enter Amount ..."
            value={lumAmount}
          />
          <button className="usdt-btn">
            <img className="usdt-bep20" src="image/logo.png" alt="matic" />
          </button>
        </div>
        <label>
          Your Balance : {parseFloat(walletBalance?.formatted).toFixed(4)}{" "}
          {walletBalance?.symbol}
        </label>
        <label>Your Balance : {Number(getBalance)} LUM</label>
        <button
          className="deposit-btn"
          onClick={buy}
          type="submit"
          disabled={isBuyLoading}
        >
          {isBuyLoading ? "Loading..." : "Buy Now"}
        </button>
      </div>

      <div className="presale-description">
        <h5>PRESALE DESCRIPTION</h5>
        <div className="presale-details">
          <p>
            Token Name: <b />
            <p className="details">Luminai</p>
          </p>
          <p>
            Token Ticker: <p className="details">LUM</p>
          </p>
          <p>
            Maximum Supply: <p className="details">1B</p>
          </p>
          <p>
            LUM Allocated: <p className="details">1,000,000</p>
          </p>
          <p>
            Rate :<p className="details">1 LUM = {LumValuePerMatic}</p>
          </p>
          <p>
            Participants :<p className="details">{Number(totalUser)}</p>
          </p>
        </div>
      </div>
    </div>
  );
};

export default Presale;
